import React from 'react'
import './styles.scss'
import ProductColumnGenerator from '../ProductColumnGenerator'
import { DEFAULT_IMG_URL } from '../../constants'
import { useRelatedProducts } from '@algolia/recommend-react'
import recommend from '@algolia/recommend'
import { getAlgoliaIndex } from '../../utils'
import algolia from '../../../config/algolia.json'


const recommendClient = recommend(algolia.appId, algolia.publicSearchOnlyKey)


export const RelatedProducts = ({ node }) => {
  const { recommendations } = useRelatedProducts({
    recommendClient,
    indexName: getAlgoliaIndex(),
    objectIDs: [node.name],
    maxRecommendations: 3,
    threshold: 0,
  })
  if (recommendations?.length > 0) {
    return (
      <div className="mt-5 mb-5">
        <h2 className="mt-6 mb-3 related-mobile">Related Products</h2>
        <div>
          <ProductColumnGenerator
            array={recommendations?.map(({ name, url, alt_text, image }) => (
              { url, name, alt_text, img: image || DEFAULT_IMG_URL, relatedProduct: true }
            ))} />
        </div>
      </div>
    )
  } else return null
}

export default RelatedProducts