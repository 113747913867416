import React from "react"
import "./styles.scss"

const renderSubList = (subList, className) => {
  if (subList) {
    return (
      <ul>
        {subList.map((subPoint, ind) => (<li key={ind} className={className}>
          {subPoint.text}
          {
            subPoint.footnote && <sup>{subPoint.footnote}</sup>
          }
        </li>))}
      </ul>
    )
  }
}

export const FeaturesAndBenefits = ({ data, customLink }) => {
  return (
    <>
      {/* <h2 className="my-0">Features and Benefits</h2> */}
      <ul className="features-and-benefits">
        {data?.map((point, ind) => {
          return (
            <div key={ind}>
              {
                point.header ? (
                  <>
                    <h5 className="mt-2">{point.header}</h5>
                    {renderSubList(point.subList, 'my-1')}
                  </>
                )
                  :
                  (
                    <>
                      <li className="mt-1 mb-3">
                        {point.caption &&
                          <> <b>{point.caption}</b>
                            &mdash;
                          </>
                        }
                        {point.text}
                        {point.footnote && <sup>{point.footnote}</sup>}
                      </li>
                      {renderSubList(point.subList, 'my-1')}
                    </>
                  )
              }
            </div>
          )
        })}
        {
          customLink &&
          (
            <li>
              <a href={customLink.pdf}>{customLink.link_name}:&nbsp;</a>{customLink.sub_text}
            </li>
          )
        }
      </ul>
    </>
  )
}

export default FeaturesAndBenefits